import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";

import * as styles from "./styles.css";

import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerTabItem extends StoryblokBlok {
  name: string;
  isActive?: boolean;
}

export interface AnimLayerBankTabsProps extends StoryblokBlok {
  tabs?: AnimLayerTabItem[];
}

export function AnimLayerBankTabs({ tabs, ...rest }: AnimLayerBankTabsProps) {
  return (
    <Box
      display="flex"
      textAppearance="phone_text_sm"
      alignItems="center"
      {...rest}
    >
      {tabs &&
        tabs.map((tab) => {
          return (
            <Box
              key={tab._uid}
              className={clsx(styles.tab, { [styles.isActive]: tab.isActive })}
            >
              {tab.name}
            </Box>
          );
        })}
    </Box>
  );
}
