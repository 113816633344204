import React, { useContext } from "react";

import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";
import { faMoneyBillTransfer } from "@fortawesome/sharp-regular-svg-icons/faMoneyBillTransfer";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { StoryblokRichText } from "~components/StoryblokRichText";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { PageQueryContext } from "~context/PageQueryContext";

import * as commonStyles from "../styles.css";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type { StoryblokFieldRichText } from "~types/storyblok.types";

type IconMapKey = keyof typeof ICON_MAP;

const ICON_MAP = {
  faCheckCircle: faCheck,
  faMoneyBillTransfer,
} as const;

export interface AnimLayerAlertProps extends BoxProps {
  icon?: IconMapKey;
  titleAlert?: StoryblokFieldRichText;
  description?: string;
  amount?: string | number;
  lang?: EnabledLanguageIsoCode;
}

export function AnimLayerAlert({
  icon = "faCheckCircle",
  title: titleOld,
  titleAlert: title,
  className: userClassName,
  description,
  amount,
  ...rest
}: AnimLayerAlertProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const dynamicIcon = ICON_MAP[icon];

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        maxWidth="maxFloatingAlertWidth"
        width="max-content"
        borderRadius="lg"
        paddingY="phoneSpacing3"
        paddingX="phoneSpacing4"
        textAlign="center"
        boxShadow="light"
        className={clsx(
          userClassName,
          variantBackgroundColor.background_white,
          commonStyles.floatingUiBg
        )}
        {...rest}
      >
        <IconFontAwesome
          icon={dynamicIcon}
          size="xl"
          color="black"
          className={styles.iconStyle}
          borderRadius="50%"
          /** Icon spacing */
          margin="phoneSpacing2.5"
          padding="phoneSpacing2"
          /** Icon size */
          width="phoneSpacing3"
          height="phoneSpacing3"
          data-motion={ANIMATED.secondary}
        />
        {title ? (
          <StoryblokRichText
            textAppearance="phone_text_lg"
            marginX="auto"
            fontWeight="semibold"
            marginBottom="phoneSpacing0"
            data-motion={ANIMATED.secondary}
            text={title}
          />
        ) : (
          titleOld && (
            <Box
              textAppearance="phone_text_lg"
              marginX="auto"
              fontWeight="semibold"
              marginBottom="phoneSpacing0"
              data-motion={ANIMATED.secondary}
            >
              {titleOld}
            </Box>
          )
        )}
        {description && (
          <Box data-motion={ANIMATED.secondary} textAppearance="phone_text_md">
            {description}
          </Box>
        )}

        {amount && (
          <Box data-motion={ANIMATED.secondary} textAppearance="phone_text_md">
            {formatCurrencyString({ amount, lang })}
          </Box>
        )}
      </AnimatedElement>
    </StoryblokEditable>
  );
}
