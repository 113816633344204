import React from "react";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerBankSelectionItem } from "../AnimLayerBankSelectionItem";

import type { AnimLayerBankSelectionItemProps } from "../AnimLayerBankSelectionItem";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Component that renders mock ui elements showing a list of available payment methods
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerBankSelectionListProps
  extends StoryblokBlok,
    BoxProps {
  items: Array<StoryblokBlok & AnimLayerBankSelectionItemProps>;
}

export function AnimLayerBankSelectionList({
  items,
  ...rest
}: AnimLayerBankSelectionListProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box {...rest}>
        {items.map((item) => {
          return (
            <AnimLayerBankSelectionItem
              data-motion={ANIMATED.secondary}
              key={item._uid}
              {...item}
            />
          );
        })}
      </Box>
    </StoryblokEditable>
  );
}
