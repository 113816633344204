import React from "react";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerBalanceCardItem } from "../AnimLayerBalanceCardItem";

import type { AnimLayerBalanceCardItemProps } from "../AnimLayerBalanceCardItem";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Renders a list of mock UI balance cards — cards displaying bank account balance, logo, etc
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerBalanceCardListProps extends BoxProps, StoryblokBlok {
  items?: Array<AnimLayerBalanceCardItemProps>;
}

export function AnimLayerBalanceCardList({
  items,
  ...rest
}: AnimLayerBalanceCardListProps) {
  if (!Array.isArray(items)) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box display="flex" gap="phoneSpacing2" flexDirection="column">
        {items.map((item) => {
          return (
            <AnimLayerBalanceCardItem
              /** Data is static, can key by index */
              // eslint-disable-next-line react/no-array-index-key
              key={item.title}
              {...item}
            />
          );
        })}
      </Box>
    </StoryblokEditable>
  );
}
