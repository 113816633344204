/**
 * ------------------------------------------------------------------------------
 * Abstraction of single payment method
 * ------------------------------------------------------------------------------
 */
import React from "react";

import { faChevronRight } from "@fortawesome/sharp-regular-svg-icons/faChevronRight";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as styles from "./styles.css";

import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimLayerBankSelectionItemProps extends StoryblokBlok {
  image: StoryblokFieldMedia;
  title: string;
}

export function AnimLayerBankSelectionItem({
  image,
  title,
  ...rest
}: AnimLayerBankSelectionItemProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box
        display="flex"
        alignItems="center"
        gap="phoneSpacing1"
        paddingX="phoneSpacing1"
        paddingY="phoneSpacing2"
        borderBottom="default"
        fontWeight="semibold"
        color="text_lowContrast"
        textAppearance="phone_text_sm"
        {...rest}
      >
        {image && (
          <Image width="phoneSpacing3" height="phoneSpacing3" image={image} />
        )}
        {title && <Box>{title}</Box>}
        <IconFontAwesome
          marginLeft="auto"
          icon={faChevronRight}
          className={styles.chevronStyle}
        />
      </Box>
    </StoryblokEditable>
  );
}
